import PrinterService from "../../Services/PrinterService";
import {
  Alignment,
  BARCODE_TYPE,
  Orientation,
  ZebraBarcode,
  ZebraGraphicBox,
  ZebraImage,
  ZebraItem,
  ZebraText,
  ZebraTextBlock,
} from "../../Models/Zebra";
import moment from "moment";
import Utils from "../../Utils/Utils";
import { Label, ProducerType } from "../../Models/Label";
import { LabelHistoryDTO } from "../../Models/LabelHistory";
import imageToZ64 from "../../Utils/ZPLImage";

const mmToInch = Utils.mmToInch;
const ptToInch = Utils.ptToInch;
const calcDots = Utils.calcDots;

export type TemplateType = (label: Label, dto: LabelHistoryDTO) => string;

const Template: TemplateType = (label: Label, dto: LabelHistoryDTO): string => {
  const labelWidth = label.type.width; //mm
  const labelHeight = label.type.height; //mm
  const dpi = 203; //300;
  const x = Math.round(mmToInch(75.545) * dpi); // don't use it yet
  const y = Math.round(mmToInch(label.titleLines >= 2 ? 20 : 17) * dpi); // 20 mm kõrgusel, 25.4 = mm to inch
  const height = Math.round(ptToInch(16) * dpi);
  const labelWidthDots = Math.round(mmToInch(labelWidth) * dpi);
  const labelHeightDots = Math.round(mmToInch(labelHeight) * dpi);
  const requiredDots = 55;
  const rightX = 50;

  const getPackagerTitle = () => {
    const title = label.producerType;
    if (title === ProducerType.IMPORTER) {
      return "Maaletooja";
    }
    if (title === ProducerType.PACKAGER) {
      return "Pakendaja";
    }
    if (title === ProducerType.PRODUCER) {
      return "Tootja";
    }
    if (title === ProducerType.EXPORTER) {
      return "Eksportija";
    }
    if (title === ProducerType.DISTRIBUTOR) {
      return "Turustaja";
    }
  };

  const title: ZebraTextBlock = {
    alignment: Alignment.LEFT,
    maxLines: Math.min(label.titleLines, 2),
    content: label.title,
    position: { x: x - Math.round(mmToInch(1) * dpi), y },
    size: {
      height: label.titleLines > 2 ? Math.round(ptToInch(14) * dpi) : height,
    },
    maxWidth: Math.round(mmToInch(65) * dpi),
    type: "ZebraTextBlock",
  };

  const nutriDataTitle: ZebraText = {
    content: "Toitumisalane teave 100 g kohta",
    position: { x: x, y: Math.round(mmToInch(33.565) * dpi) },
    size: { height: Math.round(ptToInch(7) * dpi) },
    type: "ZebraText",
  };

  const energyChars = "Energiasisaldus ";
  const nutriDataEnergy: ZebraText = {
    content:
      "Energiasisaldus " + ".".repeat(calcDots(energyChars, requiredDots - 7)),
    position: { x: x, y: Math.round(mmToInch(37.8) * dpi) },
    size: { height: Math.round(ptToInch(6) * dpi) },
    type: "ZebraText",
  };

  const nutriDataEnergyValue: ZebraTextBlock = {
    alignment: Alignment.RIGHT,
    maxLines: 1,
    content:
      label.product.energyKcal + " kcal/" + label.product.energyKj + " kJ",
    position: {
      x: x + Math.round(mmToInch(rightX) * dpi),
      y: nutriDataEnergy.position.y,
    },
    size: { height: nutriDataEnergy.size.height },
    maxWidth: (190 * dpi) / 300,
    type: "ZebraTextBlock",
  };

  const fatChars = "Rasvad ";
  const nutriDataFat: ZebraText = {
    content: "Rasvad " + ".".repeat(calcDots(fatChars, requiredDots)),
    position: {
      x: x,
      y: Math.round(nutriDataEnergy.position.y + nutriDataEnergy.size.height),
    },
    size: { height: Math.round(ptToInch(6) * dpi) },
    type: "ZebraText",
  };

  const nutriDataFatValue: ZebraTextBlock = {
    ...nutriDataEnergyValue,
    content: label.product.fat + " g",
    position: {
      x: x + Math.round(mmToInch(rightX) * dpi),
      y: nutriDataFat.position.y,
    },
  };

  const satFatChars = "Millest küllastunud rasvad";
  const nutriDataSatFat: ZebraText = {
    content:
      "Millest küllastunud rasvad " +
      ".".repeat(calcDots(satFatChars, requiredDots)),
    position: {
      x: x,
      y: Math.round(nutriDataFat.position.y + nutriDataFat.size.height),
    },
    size: { height: Math.round(ptToInch(6) * dpi) },
    type: "ZebraText",
  };

  const nutriDataSatFatValue: ZebraTextBlock = {
    ...nutriDataEnergyValue,
    content: label.product.satFat + " g",
    position: {
      x: x + Math.round(mmToInch(rightX) * dpi),
      y: nutriDataSatFat.position.y,
    },
  };

  const carbs = "Süsivesikud ";
  const nutriDataCarbs: ZebraText = {
    content: carbs + ".".repeat(calcDots(carbs, requiredDots)),
    position: {
      x: x,
      y: Math.round(nutriDataSatFat.position.y + nutriDataSatFat.size.height),
    },
    size: { height: Math.round(ptToInch(6) * dpi) },
    type: "ZebraText",
  };

  const nutriDataCarbsValue: ZebraTextBlock = {
    ...nutriDataEnergyValue,
    content: label.product.carbs + " g",
    position: {
      x: x + Math.round(mmToInch(rightX) * dpi),
      y: nutriDataCarbs.position.y,
    },
  };

  const sugars = "Millest suhkrud  ";
  const nutriDataSugars: ZebraText = {
    content: sugars + ".".repeat(calcDots(sugars, requiredDots)),
    position: {
      x: x,
      y: Math.round(nutriDataCarbs.position.y + nutriDataCarbs.size.height),
    },
    size: { height: Math.round(ptToInch(6) * dpi) },
    type: "ZebraText",
  };

  const nutriDataSugarsValue: ZebraTextBlock = {
    ...nutriDataEnergyValue,
    content: label.product.sugar + " g",
    position: {
      x: x + Math.round(mmToInch(rightX) * dpi),
      y: nutriDataSugars.position.y,
    },
  };

  const protein = "Valgud ";
  const nutriDataProtein: ZebraText = {
    content: protein + ".".repeat(calcDots(protein, requiredDots)),
    position: {
      x: x,
      y: Math.round(nutriDataSugars.position.y + nutriDataSugars.size.height),
    },
    size: { height: Math.round(ptToInch(6) * dpi) },
    type: "ZebraText",
  };

  const nutriDataProteinValue: ZebraTextBlock = {
    ...nutriDataEnergyValue,
    content: label.product.protein + " g",
    position: {
      x: x + Math.round(mmToInch(rightX) * dpi),
      y: nutriDataProtein.position.y,
    },
  };

  const salt = "Sool ";
  const nutriDataSalt: ZebraText = {
    content: salt + ".".repeat(calcDots(salt, requiredDots)),
    position: {
      x: x,
      y: Math.round(nutriDataProtein.position.y + nutriDataProtein.size.height),
    },
    size: { height: Math.round(ptToInch(6) * dpi) },
    type: "ZebraText",
  };

  const nutriDataSaltValue: ZebraTextBlock = {
    ...nutriDataEnergyValue,
    content: label.product.salt + " g",
    position: {
      x: x + Math.round(mmToInch(rightX) * dpi),
      y: nutriDataSalt.position.y,
    },
  };

  const ingredients: ZebraText = {
    content: "Koostisosad: " + label.ingredients,
    position: { x: x, y: Math.round(mmToInch(58.5) * dpi) },
    size: { height: Math.round(ptToInch(6) * dpi) },
    type: "ZebraText",
  };

  const originY = label.hasIngredients
    ? Math.round(ingredients.position.y + ingredients.size.height)
    : Math.round(mmToInch(58.5) * dpi);

  const origin: ZebraText = {
    content: "Päritolumaa: " + dto.origin,
    position: { x: x, y: originY },
    size: { height: Math.round(ptToInch(6) * dpi) },
    type: "ZebraText",
  };

  const img = document.getElementById("organic_90");
  const organicFlag = imageToZ64(img);
  let zpl = `^GFA,${organicFlag.length},${organicFlag.length},${organicFlag.rowlen},${organicFlag.z64}`;
  const flag: ZebraImage = {
    content: zpl,
    type: "ZebraImage",
    position: {
      x: Math.round(mmToInch(label.type.width - 13.5 - 7.5) * dpi),
      y: Math.round(mmToInch(61.5) * dpi),
    },
    size: title.size,
  };

  const eeoko03: ZebraTextBlock = {
    content: "EE-ÖKO-03\\& Eesti põllumajandus",
    size: { height: Math.round(ptToInch(4.5) * dpi) },
    position: {
      x: flag.position.x,
      y: flag.position.y + Math.round(mmToInch(8) * dpi),
    },
    maxLines: 2,
    maxWidth: Math.round(mmToInch(13.5) * dpi),
    alignment: Alignment.LEFT,
    type: "ZebraTextBlock",
  };

  const bestBefore: ZebraText = {
    content: `Parim enne: ${moment(new Date(dto.bestBefore)).format(
      `DD.MM.YYYY`
    )}. Lot: ${dto.lot}`,
    position: { x: x, y: Math.round(origin.position.y + origin.size.height) },
    size: { height: Math.round(ptToInch(6) * dpi) },
    type: "ZebraText",
  };
  /*const lot: ZebraText = {
    content: "Lot: " + dto.lot,
    position: {
      x: x,
      y: Math.round(bestBefore.position.y + bestBefore.size.height),
    },
    size: { height: Math.round(ptToInch(6) * dpi) },
    type: "ZebraText",
  };*/
  const KeepAt: ZebraText = {
    content: "Säilitada temp: -20°C...-18°C",
    position: {
      x: x,
      y: Math.round(bestBefore.position.y + bestBefore.size.height),
    },
    size: { height: Math.round(ptToInch(6) * dpi) },
    type: "ZebraText",
  };

  const stoneWarning: ZebraText = {
    content: "Võib sisaldab viljakivi osakesi.",
    position: { x: x, y: Math.round(KeepAt.position.y + KeepAt.size.height) },
    size: { height: Math.round(ptToInch(6) * dpi) },
    type: "ZebraText",
  };

  const doNotFreezeTopElement = label.hasStoneWarning ? stoneWarning : KeepAt;
  const doNotFreeze: ZebraText = {
    content: "Sulanud toodet uuesti mitte külmutada!",
    position: {
      x: x,
      y: Math.round(
        doNotFreezeTopElement.position.y + doNotFreezeTopElement.size.height
      ),
    },
    size: { height: Math.round(ptToInch(6) * dpi) },
    type: "ZebraText",
  };

  const packagerTitle = getPackagerTitle();
  const packager: ZebraText = {
    content: packagerTitle + ": OÜ Figuraata, Jõe 11B, Räpina, Põlvamaa 64505",
    position: {
      x: x,
      y: Math.round(doNotFreeze.position.y + doNotFreeze.size.height),
    },
    size: { height: Math.round(ptToInch(6) * dpi) },
    type: "ZebraText",
  };

  const barcode: ZebraBarcode = {
    barcodeType: BARCODE_TYPE.EAN13,
    orientation: Orientation.ROTATED_0,
    code: label.barcode,
    position: { x: x + 30, y: Math.round(mmToInch(77) * dpi) },
    size: { height: Math.round(mmToInch(9.5) * dpi) },
    type: "ZebraBarcode",
  };

  let weightHeight = Math.round(ptToInch(18) * dpi);
  let weightValue;

  if (label.weight === 500) {
    weightValue = "500 g";
  } else if (label.weight === 1000) {
    weightValue = "1 kg";
  } else if (label.weight === 2500) {
    weightValue = "2.5 kg";
  } else if (label.weight > 1000) {
    weightValue = label.weight / 1000 + " kg";
  } else {
    weightValue = label.weight + " g";
  }

  let weight: ZebraTextBlock = {
    alignment: Alignment.CENTER,
    content: weightValue,
    maxLines: 1,
    maxWidth: Math.round(mmToInch(22) * dpi),
    position: {
      x: Math.round(mmToInch(123) * dpi),
      y: Math.round(mmToInch(81) * dpi),
    },
    size: { height: weightHeight },
    type: "ZebraTextBlock",
  };

  if (dto.isBoxLabel) {
    weightHeight = Math.round(ptToInch(12) * dpi);
    let unitWeight = "500 g";
    if (label.weight === 2500) {
      unitWeight = "2.5 kg";
      weightHeight = Math.round(ptToInch(11) * dpi);
    } else {
      unitWeight = "1 kg";
    }

    weightValue = `${dto.unitSize} kg\\&(${unitWeight} x ${
      (dto.unitSize * 1000) / label.weight
    })`;

    weight = {
      ...weight,
      content: weightValue,
      maxLines: 2,
      position: {
        ...weight.position,
        y: weight.position.y + Math.round(mmToInch(2) * dpi),
      },
      size: { height: weightHeight },
    };
  }

  const box: ZebraGraphicBox = {
    size: {
      width: weight.maxWidth,
      height: weight.maxLines * weight.size.height,
    },
    thickness: 3,
    position: weight.position,
    type: "ZebraGraphicBox",
  };

  let labelParts: ZebraItem[] = [
    title,
    nutriDataTitle,
    nutriDataEnergy,
    nutriDataEnergyValue,
    nutriDataFat,
    nutriDataFatValue,
    nutriDataSatFat,
    nutriDataSatFatValue,
    nutriDataCarbs,
    nutriDataCarbsValue,
    nutriDataSugars,
    nutriDataSugarsValue,
    nutriDataProtein,
    nutriDataProteinValue,
    nutriDataSalt,
    nutriDataSaltValue,
    origin,
    bestBefore,
    //lot,
    KeepAt,
    doNotFreeze,
    packager,
    barcode,
    weight,
    //       box
  ];

  if (label.isOrganic) {
    labelParts.push(flag, eeoko03);
  }

  if (label.hasStoneWarning) {
    labelParts.push(stoneWarning);
  }

  if (label.hasIngredients) {
    labelParts.push(ingredients);
  }

  console.log("LABEL", label);

  const builtLabel = PrinterService.buildLabel(
    {
      labelWidth: labelWidthDots,
      orientation: Orientation.ROTATED_90,
      labelHeight: labelHeightDots,
      quantity: dto.quantity,
      flippedHorizontally: label.flippedHorizontally,
    },
    labelParts
  );
  return builtLabel;
};

export { Template as build };
