import { TelemaOrder, TelemaOrderRef } from "../../Models/TelemaOrder";
import { TelemaProduct } from "../../Models/TelemaProduct";
import { TelemaPartner } from "../../Models/TelemaPartner";
import {
  TelemaBulkOrder,
  TelemaBulkOrderDTO,
} from "../../Models/TelemaBulkOrder";
import { TelemaDeliveryNote } from "../../Models/TelemaDeliveryNote";
import { TelemaProductBatch } from "../../Models/TelemaProductBatch";
import { TelemaRecAdv } from "../../Models/TelemaRecAdv";
const _ = require("lodash");

const mapToOrder = (order: any): TelemaOrder => ({
  id: order.id,
  orderNumber: order.order_number,
  orderId: order.order_id,
  date: order.order_date,
  confirmDate: order.order_confirmed_date,
  deliveryDate: order.order_delivery_date,
  orderedByEmail: order.order_made_by_email,
  orderedByPhone: order.order_made_by_phone,
  products:
    (order.telema_products && order.telema_products.map(mapToProduct)) || [],
  buyer: mapToPartner(order.buyer),
  seller: mapToPartner(order.sold_by),
  deliveryParty: mapToPartner(order.destination),
  orderParty: mapToPartner(order.ordered_by),
  bulkOrder: order.telema_bulk_order
    ? mapToBulkOrder(order.telema_bulk_order, false)
    : undefined,
});

const mapToOrderRef = (order: any): TelemaOrderRef => ({
  orderNumber: order.order_number,
  products: order.telema_products.map(mapToProduct),
});
const mapToBulkOrder = (b: any, single: boolean): TelemaBulkOrder => {
  let order: TelemaBulkOrder = {
    id: b.id,
    issueDate: b.issue_date,
    orderDate: b.order_date,
    deliveryDate: b.delivery_date,
    orderNumber: b.order_number,
    orders: [],
    deliveryNote:
      (b.telema_delivery_note &&
        !(typeof b.telema_delivery_note === "number") &&
        mapToDeliveryNote(b.telema_delivery_note)) ||
      undefined,
  };
  if (single) {
    order.orders = (b.telema_orders && b.telema_orders.map(mapToOrder)) || [];
  } else {
    order.orders =
      (b.telema_orders && b.telema_orders.map(mapToOrderRef)) || [];
  }
  return order;
};

const mapToDeliveryNote = (d: any): TelemaDeliveryNote => {
  console.log(d);
  return {
    documentNumber: d.delivery_note_number,
    products:
      d.delivery_file_json["E-Document"].Document.DocumentItem.ItemEntry.map(
        mapXMLJSONToProduct
      ),
  };
};
const mapXMLJSONToProduct = (i: any): TelemaProduct => ({
  id: Number(i.LineItemNum._text),
  sellerGTIN: i.SellerItemCode._text,
  buyerGTIN: "Not used",
  productName: i.ItemDescription._text,
  unit: "tk",
  origin: getOriginFromXMLJSON(i),
  quantity: Number(i.AmountOrdered._text) * 8,
  batches: removeDuplicateBatches(
    Array.isArray(i.ItemReserve)
      ? i.ItemReserve.map(getBatchFromXMLJSON)
      : [getBatchFromXMLJSON(i.ItemReserve)]
  ),
  deliveredQuantity: Number(i.AmountDespatched._text) * 8,
});

function removeDuplicateBatches(
  batches: TelemaProductBatch[]
): TelemaProductBatch[] {
  // Group the array by 'id'
  const grouped = _.groupBy(batches, "lot");

  // Map the grouped data to calculate sums
  const outputArray = _.map(grouped, (group: TelemaProductBatch[]) => {
    const lot = group[0].lot;
    const bestBefore = group[0].bestBefore;
    const qSum = _.sumBy(group, "quantity");
    return { lot, bestBefore, quantity: qSum };
  });
  return outputArray;
}

function getOriginFromXMLJSON(itemEntry: any): string {
  try {
    if (Array.isArray(itemEntry.ItemReserve)) {
      const origins = [
        ...new Set<string>(
          itemEntry.ItemReserve.map(
            (reserve: any) => reserve.AdditionalInfo.Extension.InfoContent._text
          )
        ),
      ];
      if (origins.length > 1) {
        alert("Product item was sent from multiple origins, handle manually");
        throw new Error("Product with multiple origins found!");
      }
      return origins[0];
    }
    return itemEntry.ItemReserve.AdditionalInfo.Extension.InfoContent._text;
  } catch (e) {
    //alert(e)
    return "No origin given";
  }
}
function getBatchFromXMLJSON(ItemReserve: any): TelemaProductBatch | undefined {
  console.log(ItemReserve);
  try {
    return {
      lot: ItemReserve.LotNum._text,
      bestBefore: ItemReserve.BestBeforeMin._text,
      quantity: Number(ItemReserve.ReserveAmount._text) * 8,
    };
  } catch (e) {
    return undefined;
  }
}

const mapToProduct = (p: any): TelemaProduct => ({
  id: p.id,
  sellerGTIN: p.seller_gtin,
  buyerGTIN: p.buyer_gtin,
  productName: p.product_name,
  quantity: p.ordered_amount,
  unit: p.unit,
  origin: p.origin,
  deliveredQuantity: p.delivered_amount,
  acceptedQuantity: p.accepted_amount,
  batches: p.telema_product_batches && p.telema_product_batches.map(mapToBatch),
});

const mapToBatch = (b: any): TelemaProductBatch => ({
  lot: b.batch_number,
  bestBefore: b.best_before_date,
  quantity: b.batch_amount,
});

const mapToPartner = (p: any): TelemaPartner => ({
  name: p.name,
  GLN: p.gln,
});

const mapBulkOrderDTO: (b: TelemaBulkOrderDTO) => any = (b) => ({
  order_date: b.orderDate,
  issue_date: b.issueDate,
  delivery_date: b.deliveryDate,
  telema_orders: b.orders.map((o) => o.id),
});

function mapToRecAdv(r: any): TelemaRecAdv {
  console.log(r);
  return {
    date: r.date,
    deliveryDate: r.delivery_date,
    deliveryNote: mapToDeliveryNote(r.telema_delivery_note),
    deliveryParty: mapToPartner(r.delivery_party),
    id: r.id,
    madeByEmail: r.made_by_email,
    madeByPhone: r.made_by_phone,
    order: mapToOrder(r.telema_order),
    orderedByParty: mapToPartner(r.ordered_by_party),
    products: r.telema_products.map(mapToProduct),
    recAdvId: r.recadv_id,
    recAdvNumber: r.recadv_number,
    sellerParty: mapToPartner(r.seller_party),
    meritInvoiceCreated: r.merit_invoice_created,
  };
}

export default {
  mapToOrder,
  mapBulkOrderDTO,
  mapToBulkOrder: (o: any) => mapToBulkOrder(o, false),
  mapToSingleBulkOrder: (o: any) => mapToBulkOrder(o, true),
  mapToRecAdv,
};
