import PrinterService from "../../Services/PrinterService";
import {
  Alignment,
  BARCODE_TYPE,
  Orientation,
  ZebraBarcode,
  ZebraGraphicBox,
  ZebraImage,
  ZebraItem,
  ZebraText,
  ZebraTextBlock,
} from "../../Models/Zebra";
import moment from "moment";
import Utils from "../../Utils/Utils";
import { Label, ProducerType } from "../../Models/Label";
import { LabelHistoryDTO } from "../../Models/LabelHistory";
import imageToZ64 from "../../Utils/ZPLImage";

const mmToInch = Utils.mmToInch;
const ptToInch = Utils.ptToInch;
const calcDots = Utils.calcDots;

export type TemplateType = (label: Label, dto: LabelHistoryDTO) => string;

const Template: TemplateType = (label: Label, dto: LabelHistoryDTO): string => {
  const labelWidth = 85; //label.type.width; //mm
  const labelHeight = 100; //label.type.height; //mm
  const dpi = 203; //300;
  const x = Math.round(mmToInch(11) * dpi); // don't use it yet
  const y = Math.round(mmToInch(label.titleLines == 2 ? 15 : 17) * dpi); // 20 mm kõrgusel, 25.4 = mm to inch
  const height = Math.round(ptToInch(16) * dpi);
  const labelWidthDots = Math.round(mmToInch(labelWidth) * dpi);
  const labelHeightDots = Math.round(mmToInch(labelHeight) * dpi);
  const requiredDots = 55;
  const rightX = 50;

  const getPackagerTitle = () => {
    const title = label.producerType;
    if (title === ProducerType.IMPORTER) {
      return "Importer";
    }
    if (title === ProducerType.PACKAGER) {
      return "Packager";
    }
    if (title === ProducerType.PRODUCER) {
      return "Producer";
    }
    if (title === ProducerType.EXPORTER) {
      return "Exporter";
    }
    if (title === ProducerType.DISTRIBUTOR) {
      return "Distributor";
    }
  };

  const translatedOrigin = () => {
    const o = dto.origin;
    if (o === "Eesti") {
      return "Estonia";
    }
    if (o === "Soome") {
      return "Finland";
    }
    return o;
  };

  const title: ZebraTextBlock = {
    alignment: Alignment.LEFT,
    maxLines: label.titleLines,
    content: label.title,
    position: { x: x - Math.round(mmToInch(1) * dpi), y },
    size: { height },
    maxWidth: Math.round(mmToInch(65) * dpi),
    type: "ZebraTextBlock",
  };

  const supplierId: ZebraText = {
    content: "Supplier id: ",
    position: { x: x, y: Math.round(mmToInch(33.565) * dpi) },
    size: { height: Math.round(ptToInch(12) * dpi) },
    type: "ZebraText",
  };

  const img = document.getElementById("organic");
  const organicFlag = imageToZ64(img);
  let zpl = `^GFA,${organicFlag.length},${organicFlag.length},${organicFlag.rowlen},${organicFlag.z64}`;
  const img2 = document.getElementById("test_label");
  const img2Z = imageToZ64(img2, { notrim: true });
  let zpl2 = `^GFA,${img2Z.length},${img2Z.length},${img2Z.rowlen},${img2Z.z64}`;

  const flag: ZebraImage = {
    content: zpl,
    type: "ZebraImage",
    position: {
      x: Math.round(mmToInch(label.type.width - 13.5 - 10) * dpi),
      y: supplierId.position.y + supplierId.size.height,
    },
    size: supplierId.size,
  };

  const pngimg: ZebraImage = {
    content: zpl2,
    type: "ZebraImage",
    position: { x: 0, y: 0 },
    size: {
      width: labelWidth,
      height: labelHeight,
    },
  };

  const eeoko03: ZebraTextBlock = {
    content: "EE-ÖKO-03\\& Estonian agriculture",
    size: { height: Math.round(ptToInch(4) * dpi) },
    position: {
      x: flag.position.x,
      y: flag.position.y + Math.round(mmToInch(10) * dpi),
    },
    maxLines: 2,
    maxWidth: Math.round(mmToInch(13.5) * dpi),
    alignment: Alignment.CENTER,
    type: "ZebraTextBlock",
  };

  const box: ZebraGraphicBox = {
    size: {
      width: Math.round(mmToInch(40) * dpi),
      height: Math.round(mmToInch(15) * dpi),
    },
    thickness: 3,
    position: { x, y: supplierId.position.y + supplierId.size.height },
    type: "ZebraGraphicBox",
  };

  const supplierIdValue: ZebraTextBlock = {
    alignment: Alignment.CENTER,
    content: "15",
    maxLines: 1,
    maxWidth: Math.round(mmToInch(40) * dpi),
    position: {
      x,
      y:
        supplierId.position.y +
        Math.round(mmToInch(3) * dpi) +
        supplierId.size.height,
    },
    size: { height: Math.round(mmToInch(11) * dpi) },
    type: "ZebraTextBlock",
  };

  /*  size: Size
    font?: Font;
    content: string;
    position: Position;
    maxLines: number;
    maxWidth: number;
    //lineSpacing: number;
    alignment: Alignment
    type: 'ZebraTextBlock'*/
  const origin: ZebraText = {
    content: "Origin: " + translatedOrigin(),
    position: { x: x, y: Math.round(mmToInch(59) * dpi) },
    size: { height: Math.round(ptToInch(7) * dpi) },
    type: "ZebraText",
  };
  let bestBefore: ZebraText = {
    content:
      "Best before: " + moment(new Date(dto.bestBefore)).format("DD.MM.YYYY"),
    position: { x: x, y: Math.round(origin.position.y + origin.size.height) },
    size: { height: Math.round(ptToInch(7) * dpi) },
    type: "ZebraText",
  };
  let lot: ZebraText = {
    content: "Lot: " + dto.lot,
    position: {
      x: x,
      y: Math.round(bestBefore.position.y + bestBefore.size.height),
    },
    size: { height: Math.round(ptToInch(7) * dpi) },
    type: "ZebraText",
  };
  console.log("lot-----", dto);
  let keepAt: ZebraText = {
    content: "Store at: -20°C...-18°C",
    position: { x: x, y: Math.round(lot.position.y + lot.size.height) },
    size: { height: Math.round(ptToInch(7) * dpi) },
    type: "ZebraText",
  };
  let doNotFreeze: ZebraText = {
    content: "Do not freeze after thawing!",
    position: { x: x, y: Math.round(keepAt.position.y + keepAt.size.height) },
    size: { height: Math.round(ptToInch(7) * dpi) },
    type: "ZebraText",
  };

  const packagerTitle = getPackagerTitle();
  let packager: ZebraText = {
    content: packagerTitle + ": OÜ Figuraata, Jõe 11B, Räpina, Põlvamaa 64505",
    position: {
      x: x,
      y: Math.round(doNotFreeze.position.y + doNotFreeze.size.height),
    },
    size: { height: Math.round(ptToInch(7) * dpi) },
    type: "ZebraText",
  };

  const barcode: ZebraBarcode = {
    barcodeType: BARCODE_TYPE.EAN13,
    orientation: Orientation.ROTATED_0,
    code: label.barcode,
    position: { x: x + 30, y: Math.round(mmToInch(77) * dpi) },
    size: { height: Math.round(mmToInch(9.5) * dpi) },
    type: "ZebraBarcode",
  };

  let weightHeight = Math.round(ptToInch(18) * dpi);
  let weightValue;

  if (dto.weight === 500) {
    weightValue = "500 g";
  } else if (dto.weight === 1000) {
    weightValue = "1 kg";
  } else if (dto.weight === 2500) {
    weightValue = "2.5 kg";
  } else if (dto.weight === 500 * 1000) {
    weightValue = "500 kg";
  } else if (dto.weight === 10 * 1000) {
    weightValue = "10 kg";
  } else if (dto.weight > 1000) {
    weightValue = dto.weight / 1000 + " kg";
  } else {
    weightValue = dto.weight + " g";
  }

  let weight: ZebraTextBlock = {
    alignment: Alignment.CENTER,
    content: weightValue,
    maxLines: 1,
    maxWidth: Math.round(mmToInch(22) * dpi),
    position: {
      x: Math.round(mmToInch(60) * dpi),
      y: Math.round(mmToInch(81) * dpi),
    },
    size: { height: weightHeight },
    type: "ZebraTextBlock",
  };

  if (dto.isBoxLabel) {
    weightHeight = Math.round(ptToInch(12) * dpi);
    let unitWeight = "500 g";
    if (label.weight === 2500) {
      unitWeight = "2.5 kg";
      weightHeight = Math.round(ptToInch(11) * dpi);
    } else {
      unitWeight = "1 kg";
    }

    weightValue = `${dto.unitSize} kg\\&(${unitWeight} x ${
      (dto.unitSize * 1000) / label.weight
    })`;

    weight = {
      ...weight,
      content: weightValue,
      maxLines: 2,
      position: {
        ...weight.position,
        y: weight.position.y - Math.round(mmToInch(2) * dpi),
      },
      size: { height: weightHeight },
    };
  }

  if (label.barcode === "-1") {
    bestBefore = {
      ...bestBefore,
      position: { x: x, y: Math.round(mmToInch(80) * dpi) },
      size: { height: Math.round(ptToInch(12) * dpi) },
    };
    lot = {
      ...lot,
      position: {
        x: x,
        y: Math.round(bestBefore.position.y + bestBefore.size.height),
      },
      size: { height: Math.round(ptToInch(12) * dpi) },
    };
    keepAt = {
      ...keepAt,
      position: { x: x, y: Math.round(origin.position.y + origin.size.height) },
    };
    doNotFreeze = {
      ...doNotFreeze,
      position: { x: x, y: Math.round(keepAt.position.y + keepAt.size.height) },
    };
    packager = {
      ...packager,
      position: {
        x: x,
        y: Math.round(doNotFreeze.position.y + doNotFreeze.size.height),
      },
    };
  }

  let labelParts: ZebraItem[] = [
    title,
    supplierId,
    origin,
    bestBefore,
    lot,
    keepAt,
    doNotFreeze,
    packager,
    weight,
    box,
    supplierIdValue,
  ];
  if (label.barcode !== "-1") {
    labelParts.push(barcode);
  }

  if (label.isOrganic) {
    labelParts.push(flag, eeoko03);
  }

  console.log("DTO", dto, lot);

  //labelParts = [pngimg]

  const builtLabel = PrinterService.buildLabel(
    {
      labelWidth: labelWidthDots,
      orientation: Orientation.ROTATED_0,
      labelHeight: labelHeightDots,
      quantity: dto.quantity,
      flippedHorizontally: label.flippedHorizontally,
    },
    labelParts
  );
  return builtLabel;
};

export { Template as build };
